body.modal-open {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: auto;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    position: fixed;
    width: 100%;
    height: 100%;
}

.content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    min-width: 400px;
    min-height: 300px;
}
@media (min-width: 576px) {
    .content {
        min-width: 560px;
    }
}

.body {
    border-radius: 20px;
}

.close {
    position: absolute;
    z-index: 99;
    top: 36px;
    right: 36px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.close:hover {
    opacity: .5;
}

.title {
    margin: 0;
    line-height: 40px;
}

  